import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  HStack,
  Badge,
  Link,
  Flex,
} from "@chakra-ui/react";
import { ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";
import { FaStore } from "react-icons/fa";
import { useBreakpointValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const BusinessCard = ({ business, translations }) => {
  const imageSize = useBreakpointValue({ base: "45px", md: "55px" });
  const headingSize = useBreakpointValue({ base: "sm", md: "md" });
  const iconSize = useBreakpointValue({ base: "22px", md: "28px" });
  const navigate = useNavigate();

  const handleButtonClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Box
      minW={{ base: "300px", sm: "350px", md: "400px" }}
      maxW="sm"
      // borderWidth="1px"
      borderRadius="xl"
      overflow="hidden"
      boxShadow="md"
      bg="white"
      p={2}
    >
      <VStack align="stretch" spacing={4} p={{ base: 3, md: 4 }}>
        <HStack spacing={3}>
          {business.imageUrl ? (
            <Image
              src={business.imageUrl}
              alt={business.name}
              boxSize={imageSize}
              objectFit="cover"
              borderRadius="full"
            />
          ) : (
            <Flex
              boxSize="55px"
              bg="gray.200"
              justifyContent="center"
              alignItems="center"
              borderRadius="full"
            >
              <FaStore size={iconSize} color="gray.500" />
            </Flex>
          )}
          <VStack align="start" spacing={1}>
            <Heading fontSize={"23"} size={headingSize}>
              {business.name}
            </Heading>
            <Badge
              colorScheme="gray"
              fontSize="xs"
              borderRadius="full"
              textTransform="none"
              fontWeight="normal"
              px={2}
              py={1}
            >
              {business.category}
            </Badge>
          </VStack>
        </HStack>

        <Text textAlign="left" mt={2}>
          {business.description}
        </Text>

        <Heading as="h3" size="md" fontStyle={"italic"} textAlign="left">
          {business.coupon.description}
        </Heading>

        {business.websiteUrl && business.googleProfileUrl ? (
          <HStack spacing={4} width="100%" mt={6}>
            <Button
              variant="secondary"
              leftIcon={<ExternalLinkIcon />}
              flex={1}
              onClick={() => handleButtonClick(business.websiteUrl)}
            >
              {translations.visitWebsite}
            </Button>
            <Button
              variant="outline"
              leftIcon={<InfoIcon />}
              flex={1}
              onClick={() => handleButtonClick(business.googleProfileUrl)}
            >
              {translations.googleProfile}
            </Button>
          </HStack>
        ) : (
          <VStack spacing={4} width="100%">
            {business.websiteUrl && (
              <Button
                variant={"primary"}
                leftIcon={<ExternalLinkIcon />}
                // borderRadius="full"
                width="100%"
                onClick={() => handleButtonClick(business.websiteUrl)}
              >
                {translations.visitWebsite}
              </Button>
            )}
            {business.googleProfileUrl && (
              <Button
                variant={"outline"}
                leftIcon={<InfoIcon />}
                // borderRadius="full"
                width="100%"
                onClick={() => handleButtonClick(business.googleProfileUrl)}
              >
                {translations.googleProfile}
              </Button>
            )}
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

export default BusinessCard;
