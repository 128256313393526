import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import { useLanguage } from "../contexts/LanguageContext";

const Privacy = () => {
  const { language } = useLanguage();

  const t = (key) => {
    const translations = {
      en: {
        title: "Privacy Policy for Digital Coupon Book Canada",
        effectiveDate: "Effective Date: September 6, 2024",
        introduction: `At Digital Coupon Book Canada ("we," "us," or "our"), we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, share, and protect your information when you use our platform and services. By using our services, you agree to the collection and use of information in accordance with this Privacy Policy.`,
        informationWeCollect: "1. Information We Collect",
        informationWeCollectContent: `We may collect and process the following information:`,
        informationWeCollectList: [
          {
            title: "Personal Information:",
            content:
              "Information that can be used to identify you, such as your name, email address, phone number, and payment details when you create an account or make a purchase.",
          },
          {
            title: "Non-Personal Information:",
            content:
              "Information that does not directly identify you, such as IP addresses, browser types, and browsing activity on our website.",
          },
          {
            title: "Usage Data:",
            content:
              "Information about how you use our platform, such as the pages you visit, the time spent on those pages, and interactions with features.",
          },
        ],
        howWeCollect: "2. How We Collect Information",
        howWeCollectContent: `We collect information from you in several ways:`,
        howWeCollectList: [
          {
            title: "Directly from You:",
            content:
              "When you create an account, make a purchase, or communicate with us.",
          },
          {
            title: "Automatically:",
            content:
              "Through cookies, web beacons, and other tracking technologies that collect usage data when you visit our website.",
          },
          {
            title: "From Third Parties:",
            content:
              "We may receive information about you from third-party services, such as payment processors or business partners, when you use their services in connection with ours.",
          },
        ],
        howWeUse: "3. How We Use Your Information",
        howWeUseContent: `We may use the information we collect for the following purposes:`,
        howWeUseList: [
          {
            title: "To Provide and Improve Our Services:",
            content:
              "Including processing payments, fulfilling orders, and enhancing the functionality of our platform.",
          },
          {
            title: "To Communicate with You:",
            content:
              "For customer support, updates, promotional offers, and responses to inquiries.",
          },
          {
            title: "To Personalize Your Experience:",
            content:
              "By understanding your preferences and tailoring content and recommendations accordingly.",
          },
          {
            title: "For Analytics and Research:",
            content:
              "To monitor and analyze usage patterns and trends to improve our platform.",
          },
          {
            title: "To Comply with Legal Obligations:",
            content:
              "We may use your information to comply with applicable laws and regulations or respond to legal requests.",
          },
        ],
        sharingInformation: "4. Sharing Your Information",
        sharingInformationContent: `We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:`,
        sharingInformationList: [
          {
            title: "With Service Providers:",
            content:
              "We may share your information with third-party service providers who assist us in operating our platform, processing payments, or providing customer support.",
          },
          {
            title: "For Legal Compliance:",
            content:
              "We may disclose your information if required to do so by law or in response to legal requests, such as subpoenas or court orders.",
          },
          {
            title: "In Business Transfers:",
            content:
              "If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.",
          },
          {
            title: "With Your Consent:",
            content:
              "We may share your information with third parties if you give us explicit consent to do so.",
          },
        ],
        protectingInformation: "5. How We Protect Your Information",
        protectingInformationContent: `We take reasonable steps to protect the security of your personal information by using industry-standard encryption and security measures. However, no method of transmission over the internet or method of electronic storage is completely secure, and we cannot guarantee the absolute security of your information.`,
        cookies: "6. Cookies and Tracking Technologies",
        cookiesContent: `We use cookies and similar technologies to collect usage data and improve your experience on our platform. You can control the use of cookies through your browser settings. However, disabling cookies may limit certain features of our platform.`,
        yourRights: "7. Your Rights and Choices",
        yourRightsContent: `You have the following rights regarding your personal information:`,
        yourRightsList: [
          {
            title: "Access:",
            content:
              "You may request access to the personal information we hold about you.",
          },
          {
            title: "Correction:",
            content:
              "You may request that we correct any inaccurate or incomplete information.",
          },
          {
            title: "Deletion:",
            content:
              "You may request that we delete your personal information, subject to legal or contractual obligations.",
          },
          {
            title: "Opt-Out:",
            content:
              "You may opt out of receiving promotional communications from us by following the unsubscribe instructions in those communications. Transactional notifications including coupon redemption, redemption status, and password resets are all outside of the scope of this opt-out.",
          },
        ],
        yourRightsFooter: `To exercise any of these rights, please contact us at`,
        dataRetention: "8. Data Retention",
        dataRetentionContent: `We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law. Once we no longer need your information, we will securely delete or anonymize it.`,
        thirdPartyLinks: "9. Third-Party Links",
        thirdPartyLinksContent: `Our platform may contain links to third-party websites or services that are not operated by us. We are not responsible for the privacy practices of these third-party sites. We encourage you to review the privacy policies of any third-party services you interact with.`,
        childrensPrivacy: "10. Children's Privacy",
        childrensPrivacyContent: `Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information.`,
        changes: "11. Changes to This Privacy Policy",
        changesContent: `We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Effective Date" at the top will be updated accordingly. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.`,
        contactUs: "12. Contact Us",
        contactUsContent: `If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:`,
        contactUsEmail: `support@digitalcouponbook.ca`,
      },
      fr: {
        title: "Politique de confidentialité de Digital Coupon Book Canada",
        effectiveDate: "Date d'entrée en vigueur : 6 septembre 2024",
        introduction: `Chez Digital Coupon Book Canada (« nous », « notre » ou « nos »), nous respectons votre vie privée et nous nous engageons à protéger vos informations personnelles. Cette politique de confidentialité décrit comment nous collectons, utilisons, partageons et protégeons vos informations lorsque vous utilisez notre plateforme et nos services. En utilisant nos services, vous acceptez la collecte et l'utilisation des informations conformément à cette politique de confidentialité.`,
        informationWeCollect: "1. Informations que nous collectons",
        informationWeCollectContent: `Nous pouvons collecter et traiter les informations suivantes :`,
        informationWeCollectList: [
          {
            title: "Informations personnelles :",
            content:
              "Informations qui peuvent être utilisées pour vous identifier, telles que votre nom, adresse e-mail, numéro de téléphone et détails de paiement lorsque vous créez un compte ou effectuez un achat.",
          },
          {
            title: "Informations non personnelles :",
            content:
              "Informations qui ne vous identifient pas directement, telles que les adresses IP, les types de navigateurs et l'activité de navigation sur notre site Web.",
          },
          {
            title: "Données d'utilisation :",
            content:
              "Informations sur la façon dont vous utilisez notre plateforme, telles que les pages que vous visitez, le temps passé sur ces pages et les interactions avec les fonctionnalités.",
          },
        ],
        howWeCollect: "2. Comment nous collectons les informations",
        howWeCollectContent: `Nous collectons des informations de plusieurs manières :`,
        howWeCollectList: [
          {
            title: "Directement de vous :",
            content:
              "Lorsque vous créez un compte, effectuez un achat ou communiquez avec nous.",
          },
          {
            title: "Automatiquement :",
            content:
              "Par le biais de cookies, de balises Web et d'autres technologies de suivi qui collectent des données d'utilisation lorsque vous visitez notre site Web.",
          },
          {
            title: "De tiers :",
            content:
              "Nous pouvons recevoir des informations vous concernant de la part de services tiers, tels que des processeurs de paiement ou des partenaires commerciaux, lorsque vous utilisez leurs services en relation avec les nôtres.",
          },
        ],
        howWeUse: "3. Comment nous utilisons vos informations",
        howWeUseContent: `Nous pouvons utiliser les informations que nous collectons aux fins suivantes :`,
        howWeUseList: [
          {
            title: "Pour fournir et améliorer nos services :",
            content:
              "Y compris le traitement des paiements, l'exécution des commandes et l'amélioration de la fonctionnalité de notre plateforme.",
          },
          {
            title: "Pour communiquer avec vous :",
            content:
              "Pour le support client, les mises à jour, les offres promotionnelles et les réponses aux demandes.",
          },
          {
            title: "Pour personnaliser votre expérience :",
            content:
              "En comprenant vos préférences et en adaptant le contenu et les recommandations en conséquence.",
          },
          {
            title: "Pour l'analyse et la recherche :",
            content:
              "Pour surveiller et analyser les modèles d'utilisation et les tendances afin d'améliorer notre plateforme.",
          },
          {
            title: "Pour se conformer aux obligations légales :",
            content:
              "Nous pouvons utiliser vos informations pour nous conformer aux lois et réglementations applicables ou répondre aux demandes légales.",
          },
        ],
        sharingInformation: "4. Partage de vos informations",
        sharingInformationContent: `Nous ne vendons ni ne louons vos informations personnelles à des tiers. Cependant, nous pouvons partager vos informations dans les circonstances suivantes :`,
        sharingInformationList: [
          {
            title: "Avec les fournisseurs de services :",
            content:
              "Nous pouvons partager vos informations avec des fournisseurs de services tiers qui nous aident à exploiter notre plateforme, à traiter les paiements ou à fournir un support client.",
          },
          {
            title: "Pour la conformité légale :",
            content:
              "Nous pouvons divulguer vos informations si la loi l'exige ou en réponse à des demandes légales, telles que des assignations à comparaître ou des ordonnances judiciaires.",
          },
          {
            title: "Dans les transferts d'entreprise :",
            content:
              "Si nous sommes impliqués dans une fusion, une acquisition ou une vente d'actifs, vos informations peuvent être transférées dans le cadre de cette transaction.",
          },
          {
            title: "Avec votre consentement :",
            content:
              "Nous pouvons partager vos informations avec des tiers si vous nous donnez votre consentement explicite pour le faire.",
          },
        ],
        protectingInformation: "5. Comment nous protégeons vos informations",
        protectingInformationContent: `Nous prenons des mesures raisonnables pour protéger la sécurité de vos informations personnelles en utilisant des mesures de cryptage et de sécurité standard de l'industrie. Cependant, aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir la sécurité absolue de vos informations.`,
        cookies: "6. Cookies et technologies de suivi",
        cookiesContent: `Nous utilisons des cookies et des technologies similaires pour collecter des données d'utilisation et améliorer votre expérience sur notre plateforme. Vous pouvez contrôler l'utilisation des cookies via les paramètres de votre navigateur. Cependant, la désactivation des cookies peut limiter certaines fonctionnalités de notre plateforme.`,
        yourRights: "7. Vos droits et choix",
        yourRightsContent: `Vous avez les droits suivants concernant vos informations personnelles :`,
        yourRightsList: [
          {
            title: "Accès :",
            content:
              "Vous pouvez demander l'accès aux informations personnelles que nous détenons à votre sujet.",
          },
          {
            title: "Correction :",
            content:
              "Vous pouvez demander que nous corrigions toute information inexacte ou incomplète.",
          },
          {
            title: "Suppression :",
            content:
              "Vous pouvez demander que nous supprimions vos informations personnelles, sous réserve d'obligations légales ou contractuelles.",
          },
          {
            title: "Désabonnement :",
            content:
              "Vous pouvez vous désabonner des communications promotionnelles de notre part en suivant les instructions de désabonnement dans ces communications. Les notifications transactionnelles, y compris le rachat de coupons, le statut de rachat et les réinitialisations de mot de passe, sont toutes en dehors du champ d'application de ce désabonnement.",
          },
        ],
        yourRightsFooter: `Pour exercer l'un de ces droits, veuillez nous contacter à`,
        dataRetention: "8. Conservation des données",
        dataRetentionContent: `Nous conservons vos informations personnelles aussi longtemps que nécessaire pour atteindre les objectifs décrits dans cette politique de confidentialité, ou comme l'exige la loi. Une fois que nous n'avons plus besoin de vos informations, nous les supprimerons ou les anonymiserons de manière sécurisée.`,
        thirdPartyLinks: "9. Liens vers des tiers",
        thirdPartyLinksContent: `Notre plateforme peut contenir des liens vers des sites Web ou des services tiers qui ne sont pas exploités par nous. Nous ne sommes pas responsables des pratiques de confidentialité de ces sites tiers. Nous vous encourageons à examiner les politiques de confidentialité de tout service tiers avec lequel vous interagissez.`,
        childrensPrivacy: "10. Confidentialité des enfants",
        childrensPrivacyContent: `Nos services ne s'adressent pas aux personnes de moins de 18 ans. Nous ne collectons pas sciemment d'informations personnelles auprès des enfants. Si nous apprenons qu'un enfant de moins de 18 ans nous a fourni des informations personnelles, nous prendrons des mesures pour supprimer ces informations.`,
        changes: "11. Modifications de cette politique de confidentialité",
        changesContent: `Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Toute modification sera publiée sur cette page, et la "Date d'entrée en vigueur" en haut sera mise à jour en conséquence. Nous vous encourageons à consulter régulièrement cette politique de confidentialité pour rester informé de la façon dont nous protégeons vos informations.`,
        contactUs: "12. Contactez-nous",
        contactUsContent: `Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité ou nos pratiques en matière de données, veuillez nous contacter à :`,
        contactUsEmail: `support@digitalcouponbook.ca`,
      },
    };
    return translations[language][key];
  };

  return (
    <Box minH="100vh" bg="gray.50">
      <Navbar external={true} />
      <Container maxW="container.xl" py={10}>
        <VStack spacing={8} align="stretch" textAlign="left">
          <Box mx={20}>
            <Heading as="h1" size="2xl">
              {t("title")}
            </Heading>
            <Text fontWeight="bold" mt={4}>
              {t("effectiveDate")}
            </Text>

            <Text mt={4}>{t("introduction")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("informationWeCollect")}
            </Heading>
            <Text>{t("informationWeCollectContent")}</Text>
            <UnorderedList mt={2}>
              {t("informationWeCollectList")?.map((item, index) => (
                <ListItem key={index}>
                  <Text as="span" fontWeight="bold">
                    {item.title}
                  </Text>{" "}
                  {item.content}
                </ListItem>
              ))}
            </UnorderedList>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("howWeCollect")}
            </Heading>
            <Text>{t("howWeCollectContent")}</Text>
            <UnorderedList mt={2}>
              {t("howWeCollectList")?.map((item, index) => (
                <ListItem key={index}>
                  <Text as="span" fontWeight="bold">
                    {item.title}
                  </Text>{" "}
                  {item.content}
                </ListItem>
              ))}
            </UnorderedList>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("howWeUse")}
            </Heading>
            <Text>{t("howWeUseContent")}</Text>
            <UnorderedList mt={2}>
              {t("howWeUseList")?.map((item, index) => (
                <ListItem key={index}>
                  <Text as="span" fontWeight="bold">
                    {item.title}
                  </Text>{" "}
                  {item.content}
                </ListItem>
              ))}
            </UnorderedList>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("sharingInformation")}
            </Heading>
            <Text>{t("sharingInformationContent")}</Text>
            <UnorderedList mt={2}>
              {t("sharingInformationList")?.map((item, index) => (
                <ListItem key={index}>
                  <Text as="span" fontWeight="bold">
                    {item.title}
                  </Text>{" "}
                  {item.content}
                </ListItem>
              ))}
            </UnorderedList>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("protectingInformation")}
            </Heading>
            <Text>{t("protectingInformationContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("cookies")}
            </Heading>
            <Text>{t("cookiesContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("yourRights")}
            </Heading>
            <Text>{t("yourRightsContent")}</Text>
            <UnorderedList mt={2}>
              {t("yourRightsList")?.map((item, index) => (
                <ListItem key={index}>
                  <Text as="span" fontWeight="bold">
                    {item.title}
                  </Text>{" "}
                  {item.content}
                </ListItem>
              ))}
            </UnorderedList>
            <Text mt={2}>
              {t("yourRightsFooter")}{" "}
              <Link href={`mailto:${t("contactUsEmail")}`} color="blue.500">
                {t("contactUsEmail")}
              </Link>
              .
            </Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("dataRetention")}
            </Heading>
            <Text>{t("dataRetentionContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("thirdPartyLinks")}
            </Heading>
            <Text>{t("thirdPartyLinksContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("childrensPrivacy")}
            </Heading>
            <Text>{t("childrensPrivacyContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("changes")}
            </Heading>
            <Text>{t("changesContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("contactUs")}
            </Heading>
            <Text>
              {t("contactUsContent")}{" "}
              <Link href={`mailto:${t("contactUsEmail")}`} color="blue.500">
                {t("contactUsEmail")}
              </Link>
            </Text>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default Privacy;
