import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Heading,
  Text,
  useToast,
  Switch,
  HStack,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  arrayUnion,
} from "firebase/firestore";
import Navbar from "../components/Navbar";
import { UserAuth } from "../contexts/AuthContext";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
  isIndividual: Yup.boolean(),
});

const OrganizationForm = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = UserAuth();

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    isIndividual: false,
  };

  const handleSubmit = async (values, actions) => {
    try {
      // First create the organization
      const organizationRef = await addDoc(collection(db, "organizations"), {
        ...values,
        users: [
          {
            userId: user.uid,
            role: "admin",
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            addedAt: new Date(),
          },
        ],
        createdBy: user.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
        approved: false,
      });

      // Then update the user's organizations array
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        organizations: arrayUnion({
          organizationId: organizationRef.id,
          name: values.name,
          role: "admin",
          addedAt: new Date(),
        }),
      });

      actions.setSubmitting(false);
      toast({
        title: "Success",
        description: "Organization created successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate(`/organization/${organizationRef.id}`);
    } catch (error) {
      console.error("Error creating organization: ", error);
      actions.setSubmitting(false);
      toast({
        title: "Error",
        description: "Failed to create organization. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack align="center" maxWidth="500px" margin="0 auto" pt={8}>
          <Heading as="h1" size="lg" textAlign="center" color="heading">
            Create New Organization
          </Heading>
          <Text textAlign="center" color="text" pb={4}>
            Create a new organization or individual profile by filling out the
            form below.
          </Text>
          <Box
            width="100%"
            bg="secondaryBackground"
            p={10}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <Form>
                  <VStack spacing={4}>
                    <Field name="isIndividual">
                      {({ field, form }) => (
                        <FormControl display="flex" alignItems="center">
                          <HStack spacing={4}>
                            <Text>Organization</Text>
                            <Switch
                              {...field}
                              id="isIndividual"
                              colorScheme="blue"
                              onChange={(e) => {
                                field.onChange(e);
                                if (e.target.checked) {
                                  form.setFieldValue(
                                    "name",
                                    `${user.firstName} ${user.lastName}`
                                  );
                                  form.setFieldValue("email", user.email);
                                } else {
                                  form.setFieldValue("name", "");
                                  form.setFieldValue("email", "");
                                }
                              }}
                            />
                            <Text>Individual</Text>
                          </HStack>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          isRequired
                        >
                          <FormLabel htmlFor="name">
                            {props.values.isIndividual
                              ? "Individual Name"
                              : "Organization Name"}
                          </FormLabel>
                          <Input
                            {...field}
                            id="name"
                            placeholder={`Enter ${
                              props.values.isIndividual
                                ? "individual"
                                : "organization"
                            } name`}
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                          isRequired
                        >
                          <FormLabel htmlFor="email">Email</FormLabel>
                          <Input
                            {...field}
                            id="email"
                            type="email"
                            placeholder="Enter email address"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="phoneNumber">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.phoneNumber && form.touched.phoneNumber
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="phoneNumber">
                            Phone Number
                          </FormLabel>
                          <Input
                            {...field}
                            id="phoneNumber"
                            placeholder="Enter phone number"
                          />
                          <FormErrorMessage>
                            {form.errors.phoneNumber}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Button
                      mt={4}
                      variant="primary"
                      isLoading={props.isSubmitting}
                      type="submit"
                      width="100%"
                    >
                      Create{" "}
                      {props.values.isIndividual
                        ? "Individual"
                        : "Organization"}
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default OrganizationForm;
