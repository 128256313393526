import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Spinner,
  Image,
  SimpleGrid,
  Flex,
  Text,
  Heading,
} from "@chakra-ui/react";
import { db, storage } from "../firebase";
import logo from "../assets/logos/logo.png";
import { useLanguage } from "../contexts/LanguageContext";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import BusinessCard from "../components/BusinessCard";
import Navbar from "../components/Navbar";

const BusinessList = () => {
  const { campaignId } = useParams();
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const { language, setLanguage, translate } = useLanguage();

  useEffect(() => {
    const fetchBusinessesAndCampaign = async () => {
      try {
        // Fetch campaign name
        const campaignRef = doc(db, "campaigns", campaignId);
        const campaignSnap = await getDoc(campaignRef);
        if (campaignSnap.exists()) {
          setCampaignName(campaignSnap.data().name);
        } else {
          setError("Campaign not found.");
          return;
        }

        // Fetch businesses
        const businessesRef = collection(db, "businesses");
        const q = query(businessesRef, where("campaignId", "==", campaignId));
        const querySnapshot = await getDocs(q);
        const businessList = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const businessData = doc.data();
            const businessId = doc.id;
            let logoUrl = null;

            try {
              const logoRef = ref(storage, `business-logos/${businessId}`);
              logoUrl = await getDownloadURL(logoRef);
            } catch (error) {
              console.error(
                `Error fetching logo for business ${businessId}:`,
                error
              );
            }

            return {
              id: businessId,
              ...businessData,
              logoUrl,
            };
          })
        );
        setBusinesses(businessList);
      } catch (err) {
        console.error("Error fetching data: ", err);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessesAndCampaign();
  }, [campaignId]);

  // Helper function to get the localized text with fallback
  const getLocalizedText = (field) => {
    if (typeof field === "object" && field !== null) {
      return field[language] || field.en || "";
    }
    return field || "";
  };

  if (loading) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" color="blue.500" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      bg="primaryBackground"
    >
      <Navbar external={true} />

      <Box maxWidth="1200px" margin="0 auto" px={4} pb={8}>
        <Heading as="h1" size="xl" mb={1} mt={20} textAlign="center">
          {translate("businessList.heading")}
        </Heading>
        <Text fontSize="xl" mb={8} textAlign="center">
          {getLocalizedText(campaignName)}
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {businesses.map((business) => (
            <BusinessCard
              key={business.id}
              business={{
                name: getLocalizedText(business.name),
                category: getLocalizedText(business.category),
                description: getLocalizedText(business.description),
                imageUrl: business.logoUrl,
                websiteUrl: business.websiteUrl,
                googleProfileUrl: business.googleProfileUrl,
                coupon: {
                  description: getLocalizedText(business.couponDetails),
                },
              }}
              translations={{
                couponDetails: translate("businessList.couponDetails"),
                visitWebsite: translate("businessList.website"),
                googleProfile: translate("businessList.googleProfile"),
              }}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default BusinessList;
