import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Heading,
  Textarea,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import axios from "axios";
import { apiUrl } from "../config";
import Navbar from "../components/Navbar";
import { UserAuth } from "../contexts/AuthContext";

const validationSchema = Yup.object().shape({
  name: Yup.object().shape({
    en: Yup.string().required("Campaign name (English) is required"),
    fr: Yup.string(),
  }),
  contactName: Yup.string().required("Contact name is required"),
  contactEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  contactPhone: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
  couponBookPrice: Yup.number()
    .positive("Price must be positive")
    .required("Coupon book price is required"),
  description: Yup.object().shape({
    en: Yup.string()
      .max(1000, "Description must be at most 1000 characters")
      .required("Description (English) is required"),
    fr: Yup.string().max(1000, "Description must be at most 1000 characters"),
  }),
  offeringURL: Yup.string().url("Invalid URL").nullable(),
});

const CampaignForm = () => {
  const { campaignId, organizationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [initialValues, setInitialValues] = useState({
    name: {
      en: location.state?.campaignName || "",
      fr: "",
    },
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    couponBookPrice: "",
    description: { en: "", fr: "" },
    offeringURL: "",
  });
  const { user } = UserAuth();

  const isEditMode = !!campaignId;

  useEffect(() => {
    const fetchCampaign = async () => {
      if (isEditMode) {
        const campaignDoc = await getDoc(doc(db, "campaigns", campaignId));
        if (campaignDoc.exists()) {
          const campaignData = campaignDoc.data();
          setInitialValues({
            ...campaignData,
            agents: {},
          });
        } else {
          toast({
            title: "Error",
            description: "Campaign not found",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          navigate(-1);
        }
      }
    };

    fetchCampaign();
  }, [campaignId, isEditMode, navigate, toast]);

  const handleSubmit = async (values, actions) => {
    try {
      const idToken = sessionStorage.getItem("idToken");
      const targetOrgId = organizationId || user.organizationId;
      let newCampaignId = campaignId;

      if (isEditMode) {
        await axios.put(
          `${apiUrl}/campaigns/${campaignId}`,
          {
            ...values,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
      } else {
        const response = await axios.post(
          `${apiUrl}/addCampaign`,
          {
            ...values,
            organizationId: targetOrgId,
            userId: user.uid,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            userEmail: user.email,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        newCampaignId = response.data.campaignId;
      }

      actions.setSubmitting(false);
      toast({
        title: "Success",
        description: `Campaign ${
          isEditMode ? "updated" : "created"
        } successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate(`/campaign/${newCampaignId}`);
    } catch (error) {
      console.error(
        `Error ${isEditMode ? "updating" : "creating"} campaign: `,
        error
      );
      actions.setSubmitting(false);
      toast({
        title: "Error",
        description: `Failed to ${
          isEditMode ? "update" : "create"
        } campaign. Please try again.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack align="center" maxWidth="500px" margin="0 auto" pt={8}>
          <Heading as="h1" size="lg" textAlign="center" color="heading">
            {isEditMode ? "Edit Campaign" : "New Campaign"}
          </Heading>
          <Text textAlign="center" color="text" pb={4}>
            {isEditMode
              ? "Edit the campaign details below."
              : "Create a new campaign by filling out the form below."}
          </Text>
          <Box
            width="100%"
            bg="secondaryBackground"
            p={10}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {(props) => (
                <Form>
                  <VStack spacing={4}>
                    <Field name="name.en">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.name?.en && form.touched.name?.en
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="name.en">
                            Campaign Name (English)
                          </FormLabel>
                          <Input
                            {...field}
                            id="name.en"
                            placeholder="Enter campaign name in English"
                          />
                          <FormErrorMessage>
                            {form.errors.name?.en}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="name.fr">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.name?.fr && form.touched.name?.fr
                          }
                        >
                          <FormLabel htmlFor="name.fr">
                            Campaign Name (French)
                          </FormLabel>
                          <Input
                            {...field}
                            id="name.fr"
                            placeholder="Enter campaign name in French (optional)"
                          />
                          <FormErrorMessage>
                            {form.errors.name?.fr}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="contactName">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.contactName && form.touched.contactName
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="contactName">
                            Contact Name
                          </FormLabel>
                          <Input
                            {...field}
                            id="contactName"
                            placeholder="Enter contact name"
                          />
                          <FormErrorMessage>
                            {form.errors.contactName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="contactEmail">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.contactEmail &&
                            form.touched.contactEmail
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="contactEmail">
                            Contact Email
                          </FormLabel>
                          <Input
                            {...field}
                            id="contactEmail"
                            type="email"
                            placeholder="Enter contact email"
                          />
                          <FormErrorMessage>
                            {form.errors.contactEmail}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="contactPhone">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.contactPhone &&
                            form.touched.contactPhone
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="contactPhone">
                            Contact Phone
                          </FormLabel>
                          <Input
                            {...field}
                            id="contactPhone"
                            placeholder="Enter contact phone"
                          />
                          <FormErrorMessage>
                            {form.errors.contactPhone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="couponBookPrice">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.couponBookPrice &&
                            form.touched.couponBookPrice
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="couponBookPrice">
                            Coupon Book Price (In CAD)
                          </FormLabel>
                          <Input
                            {...field}
                            id="couponBookPrice"
                            type="number"
                            step="0.01"
                            placeholder="Enter coupon book price"
                          />
                          <FormErrorMessage>
                            {form.errors.couponBookPrice}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="description.en">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.description?.en &&
                            form.touched.description?.en
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="description.en">
                            Description (English)
                          </FormLabel>
                          <Textarea
                            {...field}
                            id="description.en"
                            placeholder="Enter campaign description in English"
                          />
                          <FormErrorMessage>
                            {form.errors.description?.en}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="description.fr">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.description?.fr &&
                            form.touched.description?.fr
                          }
                        >
                          <FormLabel htmlFor="description.fr">
                            Description (French)
                          </FormLabel>
                          <Textarea
                            {...field}
                            id="description.fr"
                            placeholder="Enter campaign description in French (optional)"
                          />
                          <FormErrorMessage>
                            {form.errors.description?.fr}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="offeringURL">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.offeringURL && form.touched.offeringURL
                          }
                        >
                          <FormLabel htmlFor="offeringURL">
                            Offering Page URL (Optional)
                          </FormLabel>
                          <Input
                            {...field}
                            id="offeringURL"
                            type="url"
                            placeholder="Enter offering page URL"
                          />
                          <FormErrorMessage>
                            {form.errors.offeringURL}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Button
                      mt={4}
                      variant={"primary"}
                      isLoading={props.isSubmitting}
                      type="submit"
                      width="100%"
                    >
                      {isEditMode ? "Update Campaign" : "Create Campaign"}
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default CampaignForm;
