import { createContext, useContext, useEffect, useState } from "react";
import {
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { db, auth } from "../firebase";

// import { apiUrl } from "../config";
import { useNavigate, useLocation } from "react-router-dom";

import { doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";

const UserContext = createContext();

// Define public routes that don't require authentication
const publicRoutes = [
  "/",
  "/login",
  "/new-customer",
  "/business-list",
  "/validate-coupon",
  "/terms",
  "/privacy",
  "/payment/success",
  "/payment/failure",
  "/payment/cancellation",
];

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const uid = user.uid;
      const email = user.email;
      const firstName = user.displayName.split(" ")[0];
      const lastName = user.displayName.split(" ").slice(1).join(" ");

      // Check if user document exists, if not create one
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        // Create new user document without approved field
        await setDoc(userDocRef, {
          firstName,
          lastName,
          email,
        });
      }

      sessionStorage.setItem("uid", uid);
      sessionStorage.setItem("firstName", firstName);
      sessionStorage.setItem("lastName", lastName);
      sessionStorage.setItem("email", email);

      setUser({
        uid,
        email,
        firstName,
        lastName,
      });

      // Always navigate to home
      navigate("/home");
    } catch (error) {
      console.error("Error signing in with Google:", error.message);
      throw error;
    }
  };

  const logout = async () => {
    if (sessionStorage.length !== 0) {
      sessionStorage.clear();
      await signOut(auth);
      setUser(null);
      navigate("/");
    }
  };

  useEffect(() => {
    let unsubscribeUser = null;

    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const idToken = await currentUser.getIdToken(true);
        sessionStorage.setItem("idToken", idToken);

        const uid = currentUser.uid;
        const userDocRef = doc(db, "users", uid);

        // Set up real-time listener for user document
        unsubscribeUser = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            const userData = doc.data();
            const userInfo = {
              uid,
              email: currentUser.email,
              firstName: userData.firstName,
              lastName: userData.lastName,
            };
            setUser(userInfo);
            // Update session storage
            sessionStorage.setItem("uid", uid);
            sessionStorage.setItem("firstName", userData.firstName);
            sessionStorage.setItem("lastName", userData.lastName);
            sessionStorage.setItem("email", currentUser.email);
          }
        });
      } else {
        setUser(null);
        sessionStorage.clear();
        if (unsubscribeUser) {
          unsubscribeUser();
        }
      }
      setLoading(false);
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeUser) {
        unsubscribeUser();
      }
    };
  }, []);

  // Simplified navigation logic
  useEffect(() => {
    const isPublicRoute = publicRoutes.some((route) =>
      location.pathname.startsWith(route)
    );

    if (!loading) {
      if (user) {
        if (location.pathname === "/" || location.pathname === "/login") {
          navigate("/home");
        }
      } else if (!isPublicRoute) {
        navigate("/");
      }
    }
  }, [user, loading, navigate, location.pathname]);

  return (
    <UserContext.Provider
      value={{
        user,
        logout,
        signInWithGoogle,
      }}
    >
      {!loading && children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
