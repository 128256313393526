import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Select,
  useToast,
  Heading,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import Navbar from "../components/Navbar";
import { apiUrl } from "../config";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const businessCategories = [
  { en: "Restaurant", fr: "Restaurant" },
  { en: "Retail", fr: "Commerce de détail" },
  { en: "Beauty & Wellness", fr: "Beauté et bien-être" },
  { en: "Fitness", fr: "Fitness" },
  { en: "Home Services", fr: "Services à domicile" },
  { en: "Professional Services", fr: "Services professionnels" },
  { en: "Automotive", fr: "Automobile" },
  { en: "Entertainment", fr: "Divertissement" },
  { en: "Education", fr: "Éducation" },
  { en: "Technology", fr: "Technologie" },
  { en: "Other", fr: "Autre" },
];

const validationSchema = Yup.object().shape({
  name: Yup.object().shape({
    en: Yup.string().required("Business name (English) is required"),
    fr: Yup.string(),
  }),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
  passcode: Yup.string()
    .min(4, "Passcode must be at least 4 characters")
    .required("Passcode is required"),
  confirmPasscode: Yup.string()
    .oneOf([Yup.ref("passcode"), null], "Passcodes must match")
    .required("Confirm passcode is required"),
  websiteUrl: Yup.string().url("Invalid URL"),
  couponDetails: Yup.object().shape({
    en: Yup.string().required("Coupon details (English) are required"),
    fr: Yup.string(),
  }),
  description: Yup.object().shape({
    en: Yup.string()
      .max(75, "Description must be at most 75 characters")
      .required("Business description (English) is required"),
    fr: Yup.string().max(75, "Description must be at most 75 characters"),
  }),
  campaignId: Yup.string().required("Campaign is required"),
  agentId: Yup.string().required("Fundraiser is required"),
  category: Yup.object().shape({
    en: Yup.string().required("Category (English) is required"),
    fr: Yup.string().required("Category (French) is required"),
  }),
  googleProfileUrl: Yup.string().url("Invalid URL"),
});

const BusinessForm = () => {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [agents, setAgents] = useState([]);
  const toast = useToast();
  const [logo, setLogo] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: { en: "", fr: "" },
    email: "",
    phoneNumber: "",
    passcode: "",
    confirmPasscode: "",
    websiteUrl: "",
    couponDetails: { en: "", fr: "" },
    campaignId: "",
    agentId: "",
    description: { en: "", fr: "" },
    category: { en: "", fr: "" },
    googleProfileUrl: "",
  });

  const isEditMode = !!businessId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch campaigns
        const campaignsRef = collection(db, "campaigns");
        const campaignsSnapshot = await getDocs(campaignsRef);
        const campaignsData = campaignsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setCampaigns(campaignsData);

        if (isEditMode) {
          const businessDoc = await getDoc(doc(db, "businesses", businessId));
          if (businessDoc.exists()) {
            const businessData = businessDoc.data();
            setInitialValues({
              ...businessData,
              confirmPasscode: businessData.passcode,
            });

            // Fetch agents for the selected campaign
            if (businessData.campaignId) {
              const agentsRef = collection(
                db,
                "campaigns",
                businessData.campaignId,
                "agents"
              );
              const agentsSnapshot = await getDocs(agentsRef);
              const agentsData = agentsSnapshot.docs.map((doc) => ({
                id: doc.id,
                name: doc.data().name,
              }));
              setAgents(agentsData);
            }
          } else {
            toast({
              title: "Error",
              description: "Business not found",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            navigate(-1);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast({
          title: "Error",
          description: "Failed to fetch data. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchData();
  }, [businessId, isEditMode, navigate, toast]);

  const handleShowClick = (field) => {
    if (field === "passcode") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleLogoChange = (event) => {
    if (event.target.files[0]) {
      setLogo(event.target.files[0]);
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      const businessesRef = collection(db, "businesses");
      const q = query(businessesRef, where("passcode", "==", values.passcode));
      const querySnapshot = await getDocs(q);
      const idToken = sessionStorage.getItem("idToken");

      if (!isEditMode && !querySnapshot.empty) {
        actions.setFieldError(
          "passcode",
          "A business with this passcode already exists"
        );
        actions.setSubmitting(false);
        return;
      }

      // Create FormData object to send as payload
      const formData = new FormData();

      for (const [key, value] of Object.entries(values)) {
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          formData.append(key, JSON.stringify(value));
        } else if (value !== undefined && value !== null) {
          formData.append(key, value);
        }
      }

      // Append the logo if it's being uploaded
      if (logo) {
        formData.append("logo", logo); // Assuming 'logo' is the file object
      }

      let response;
      if (isEditMode) {
        // Use the PUT endpoint to update the business
        response = await axios.put(
          `${apiUrl}/businesses/${businessId}`, // Your update endpoint
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${idToken}`, // Add the token if necessary
            },
          }
        );
      } else {
        // Use the POST endpoint to add a new business
        response = await axios.post(
          `${apiUrl}/addBusiness`, // Your add endpoint
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${idToken}`, // Add the token if necessary
            },
          }
        );
      }

      console.log(
        `Business ${isEditMode ? "updated" : "added"} successfully:`,
        response.data
      );

      actions.setSubmitting(false);
      if (!isEditMode) actions.resetForm();
      toast({
        title: "Success",
        description: `Business ${
          isEditMode ? "updated" : "added"
        } successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate(-1);
    } catch (error) {
      console.error(
        `Error ${isEditMode ? "updating" : "adding"} business:`,
        error
      );
      actions.setSubmitting(false);
      toast({
        title: "Error",
        description: `Failed to ${
          isEditMode ? "update" : "add"
        } business. Please try again.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack
          spacing={8}
          align="center"
          maxWidth="500px"
          margin="0 auto"
          pt={8}
        >
          <Heading as="h1" size="lg" textAlign="center" color="heading">
            {isEditMode ? "Edit Advertiser" : "Add Advertiser"}
          </Heading>
          <Box
            width="100%"
            bg="secondaryBackground"
            p={10}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {(props) => (
                <Form>
                  <VStack spacing={4}>
                    <Field name="name.en">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.name?.en && form.touched.name?.en
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="name.en">
                            Business Name (English)
                          </FormLabel>
                          <Input
                            {...field}
                            id="name.en"
                            placeholder="Enter business name in English"
                          />
                          <FormErrorMessage>
                            {form.errors.name?.en}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="name.fr">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.name?.fr && form.touched.name?.fr
                          }
                        >
                          <FormLabel htmlFor="name.fr">
                            Business Name (French)
                          </FormLabel>
                          <Input
                            {...field}
                            id="name.fr"
                            placeholder="Enter business name in French (optional)"
                          />
                          <FormErrorMessage>
                            {form.errors.name?.fr}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                          isRequired
                        >
                          <FormLabel htmlFor="email">Email</FormLabel>
                          <Input
                            {...field}
                            id="email"
                            placeholder="Enter email"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="phoneNumber">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.phoneNumber && form.touched.phoneNumber
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="phoneNumber">
                            Phone Number
                          </FormLabel>
                          <Input
                            {...field}
                            id="phoneNumber"
                            placeholder="Enter phone number"
                          />
                          <FormErrorMessage>
                            {form.errors.phoneNumber}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="passcode">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.passcode && form.touched.passcode
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="passcode">Passcode</FormLabel>
                          <InputGroup>
                            <Input
                              {...field}
                              id="passcode"
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter passcode"
                            />
                            <InputRightElement width="4.5rem">
                              <Button
                                h="1.75rem"
                                size="sm"
                                onClick={() => handleShowClick("passcode")}
                              >
                                {showPassword ? "Hide" : "Show"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.passcode}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="confirmPasscode">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.confirmPasscode &&
                            form.touched.confirmPasscode
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="confirmPasscode">
                            Confirm Passcode
                          </FormLabel>
                          <InputGroup>
                            <Input
                              {...field}
                              id="confirmPasscode"
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="Confirm passcode"
                            />
                            <InputRightElement width="4.5rem">
                              <Button
                                h="1.75rem"
                                size="sm"
                                onClick={() =>
                                  handleShowClick("confirmPasscode")
                                }
                              >
                                {showConfirmPassword ? "Hide" : "Show"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.confirmPasscode}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="websiteUrl">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.websiteUrl && form.touched.websiteUrl
                          }
                        >
                          <FormLabel htmlFor="websiteUrl">
                            Website URL
                          </FormLabel>
                          <Input
                            {...field}
                            id="websiteUrl"
                            placeholder="Enter website URL"
                          />
                          <FormErrorMessage>
                            {form.errors.websiteUrl}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="description.en">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.description?.en &&
                            form.touched.description?.en
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="description.en">
                            Business Description (English)
                          </FormLabel>
                          <Textarea
                            {...field}
                            id="description.en"
                            placeholder="Enter business description in English (max 75 characters)"
                            maxLength={75}
                          />
                          <FormErrorMessage>
                            {form.errors.description?.en}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="description.fr">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.description?.fr &&
                            form.touched.description?.fr
                          }
                        >
                          <FormLabel htmlFor="description.fr">
                            Business Description (French)
                          </FormLabel>
                          <Textarea
                            {...field}
                            id="description.fr"
                            placeholder="Enter business description in French (max 75 characters, optional)"
                            maxLength={75}
                          />
                          <FormErrorMessage>
                            {form.errors.description?.fr}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="couponDetails.en">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.couponDetails?.en &&
                            form.touched.couponDetails?.en
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="couponDetails.en">
                            Coupon Details (English)
                          </FormLabel>
                          <Textarea
                            {...field}
                            id="couponDetails.en"
                            placeholder="Enter coupon details in English"
                          />
                          <FormErrorMessage>
                            {form.errors.couponDetails?.en}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="couponDetails.fr">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.couponDetails?.fr &&
                            form.touched.couponDetails?.fr
                          }
                        >
                          <FormLabel htmlFor="couponDetails.fr">
                            Coupon Details (French)
                          </FormLabel>
                          <Textarea
                            {...field}
                            id="couponDetails.fr"
                            placeholder="Enter coupon details in French (optional)"
                          />
                          <FormErrorMessage>
                            {form.errors.couponDetails?.fr}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="campaignId">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.campaignId && form.touched.campaignId
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="campaignId">Campaign</FormLabel>
                          <Select
                            {...field}
                            id="campaignId"
                            placeholder="Select campaign"
                            value={field.value || initialValues.campaignId}
                            onChange={(e) => {
                              field.onChange(e);
                              const fetchAgents = async () => {
                                const agentsRef = collection(
                                  db,
                                  "campaigns",
                                  e.target.value,
                                  "agents"
                                );
                                const agentsSnapshot = await getDocs(agentsRef);
                                const agentsData = agentsSnapshot.docs.map(
                                  (doc) => ({
                                    id: doc.id,
                                    name: doc.data().name,
                                  })
                                );
                                setAgents(agentsData);
                              };
                              fetchAgents();
                            }}
                          >
                            {campaigns.map((campaign) => (
                              <option key={campaign.id} value={campaign.id}>
                                {campaign.name.en}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.campaignId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="agentId">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.agentId && form.touched.agentId
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="agentId">Fundraiser</FormLabel>
                          <Select
                            {...field}
                            id="agentId"
                            placeholder="Select Fundraiser"
                            value={field.value || initialValues.agentId}
                            isDisabled={!form.values.campaignId}
                          >
                            {agents.map((agent) => (
                              <option key={agent.id} value={agent.id}>
                                {agent.name}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.agentId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="category">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.category && form.touched.category
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="category">Category</FormLabel>
                          <Select
                            {...field}
                            id="category"
                            placeholder="Select business category"
                            onChange={(e) => {
                              const selectedCategory = businessCategories.find(
                                (cat) => cat.en === e.target.value
                              );
                              form.setFieldValue("category", selectedCategory);
                            }}
                            value={field.value.en}
                          >
                            {businessCategories.map((category) => (
                              <option key={category.en} value={category.en}>
                                {`${category.en} / ${category.fr}`}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.category?.en ||
                              form.errors.category?.fr}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="googleProfileUrl">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.googleProfileUrl &&
                            form.touched.googleProfileUrl
                          }
                        >
                          <FormLabel htmlFor="googleProfileUrl">
                            Google Profile URL (optional)
                          </FormLabel>
                          <Input
                            {...field}
                            id="googleProfileUrl"
                            placeholder="Enter Google Profile URL"
                          />
                          <FormErrorMessage>
                            {form.errors.googleProfileUrl}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="logo">
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel htmlFor="logo">Business Logo</FormLabel>
                          <Input
                            type="file"
                            id="logo"
                            accept="image/*"
                            onChange={handleLogoChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Button
                      mt={4}
                      variant={"primary"}
                      isLoading={props.isSubmitting}
                      type="submit"
                      width="100%"
                    >
                      {isEditMode ? "Update Advertiser" : "Add Advertiser"}
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default BusinessForm;
