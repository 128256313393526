import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  Flex,
  Spinner,
  useToast,
  SimpleGrid,
  Icon,
  HStack,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../firebase";
import Navbar from "../components/Navbar";
import {
  FaChevronRight,
  FaPlus,
  FaMegaphone,
  FaUsers,
  FaHandshake,
  FaDollarSign,
  FaMoneyCheckAlt,
  FaExternalLinkAlt,
  FaCheckCircle,
  FaExclamationCircle,
  FaCog,
  FaExclamationTriangle,
} from "react-icons/fa";
import { VscMegaphone } from "react-icons/vsc";
import axios from "axios";
import { apiUrl } from "../config";

const OrganizationHome = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [organization, setOrganization] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const cardBg = useColorModeValue("white", "gray.700");
  const [stats, setStats] = useState({
    totalRaised: 0,
    totalAgents: 0,
    totalSupporters: 0,
  });
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [addUserError, setAddUserError] = useState("");
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [isRemovingUser, setIsRemovingUser] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  useEffect(() => {
    const fetchOrganizationAndCampaigns = async () => {
      try {
        // Fetch organization
        const orgDoc = await getDoc(doc(db, "organizations", organizationId));
        if (!orgDoc.exists()) {
          toast({
            title: "Error",
            description: "Organization not found",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          navigate("/");
          return;
        }

        const orgData = orgDoc.data();
        console.log("Organization data:", orgData);

        setOrganization({
          id: orgDoc.id,
          ...orgData,
          approved: orgData.approved ?? false,
        });

        // Fetch campaigns if they exist
        if (orgDoc.data().campaigns?.length > 0) {
          const campaignPromises = orgDoc
            .data()
            .campaigns.map((campaignId) =>
              getDoc(doc(db, "campaigns", campaignId))
            );
          const campaignDocs = await Promise.all(campaignPromises);
          const campaignsData = campaignDocs
            .filter((doc) => doc.exists())
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
          setCampaigns(campaignsData);

          // Calculate stats
          const stats = campaignsData.reduce(
            (acc, campaign) => ({
              totalRaised: acc.totalRaised + (campaign.totalRaised || 0),
              totalAgents: acc.totalAgents + (campaign.agents?.length || 0),
              totalSupporters:
                acc.totalSupporters + (campaign.supporters?.length || 0),
            }),
            { totalRaised: 0, totalAgents: 0, totalSupporters: 0 }
          );
          setStats(stats);
        }

        // Fetch users - updated to handle user objects in the array
        if (orgData.users?.length > 0) {
          // Each user object in orgData.users already contains the necessary information
          // We just need to format it for the UI
          const usersData = orgData.users.map((user) => ({
            id: user.userId,
            name: user.name,
            email: user.email,
            role: user.role,
            addedAt: user.addedAt,
          }));
          setUsers(usersData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast({
          title: "Error",
          description: "Failed to load organization details",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
        setLoadingUsers(false);
      }
    };

    fetchOrganizationAndCampaigns();
  }, [organizationId, navigate, toast]);

  const handleCreateCampaign = () => {
    navigate(`/create-campaign/${organizationId}`);
  };

  const handleCampaignClick = (campaignId) => {
    navigate(`/campaign/${campaignId}`);
  };

  const handleInitializeStripe = async () => {
    try {
      setLoading(true);
      const idToken = sessionStorage.getItem("idToken");

      // Create Stripe account
      const accountResponse = await axios.post(
        `${apiUrl}/create_stripe_account`,
        {
          organizationId,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const accountId = accountResponse.data.account;

      // Create account link for onboarding
      const linkResponse = await axios.post(
        `${apiUrl}/link_stripe_account`,
        {
          account: accountId,
          organizationId: organizationId,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      // Redirect to Stripe onboarding
      window.location.href = linkResponse.data.url;
    } catch (error) {
      console.error("Error initializing Stripe:", error);
      toast({
        title: "Error",
        description: "Failed to initialize Stripe account. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDashboardClick = async () => {
    try {
      const idToken = sessionStorage.getItem("idToken");
      const response = await axios.post(
        `${apiUrl}/create-dashboard-link`,
        {
          accountId: organization.stripeAccountId,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      window.open(response.data.url, "_blank");
    } catch (error) {
      console.error("Error creating dashboard link:", error);
      toast({
        title: "Error",
        description: "Failed to open Stripe dashboard. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddUser = async () => {
    try {
      setIsAddingUser(true);
      setAddUserError("");

      // Check if email exists in users collection
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", newUserEmail));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setAddUserError("No user found with this email address");
        return;
      }

      // Get the user document
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();

      // Create user object for organization
      const userObject = {
        addedAt: new Date(),
        email: userData.email,
        name: userData.firstName + " " + userData.lastName,
        role: "admin", // default role
        userId: userDoc.id,
      };

      // Create organization object for user
      const organizationObject = {
        addedAt: new Date(),
        name: organization.name,
        organizationId: organizationId,
        role: "admin", // default role
      };

      // Update organization with new user object
      await updateDoc(doc(db, "organizations", organizationId), {
        users: arrayUnion(userObject),
      });

      // Update user's record with the organization object
      await updateDoc(doc(db, "users", userDoc.id), {
        organizations: arrayUnion(organizationObject),
      });

      toast({
        title: "Success",
        description: "User added successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Update local state with new user
      setUsers([...users, { id: userDoc.id, ...userData }]);
      setNewUserEmail("");
      setIsAddUserModalOpen(false);
    } catch (error) {
      console.error("Error adding user:", error);
      setAddUserError("Failed to add user. Please try again.");
    } finally {
      setIsAddingUser(false);
    }
  };

  const handleRemoveUser = async (userId) => {
    try {
      setIsRemovingUser(true);

      // Remove user from organization
      await updateDoc(doc(db, "organizations", organizationId), {
        users: users
          .filter((user) => user.id !== userId)
          .map((user) => user.id),
      });

      // Remove organization from user's organizations
      await updateDoc(doc(db, "users", userId), {
        organizations: arrayRemove(organizationId),
      });

      // Update local state
      setUsers(users.filter((user) => user.id !== userId));

      toast({
        title: "Success",
        description: "User removed successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error removing user:", error);
      toast({
        title: "Error",
        description: "Failed to remove user. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsRemovingUser(false);
    }
  };

  const initiateRemoveUser = (user) => {
    setUserToRemove(user);
    setIsRemoveModalOpen(true);
  };

  const confirmRemoveUser = async () => {
    if (!userToRemove) return;

    try {
      setIsRemovingUser(true);
      await handleRemoveUser(userToRemove.id);
      setIsRemoveModalOpen(false);
      setUserToRemove(null);
    } finally {
      setIsRemovingUser(false);
    }
  };

  const PendingApprovalMessage = () => (
    <Box
      minH="calc(100vh - 60px)"
      bg="primaryBackground"
      pt="60px"
      px={{ base: 4, md: 10 }}
    >
      <Container maxW="container.xl" py={8}>
        <Box bg={cardBg} p={8} borderRadius="lg" shadow="md" textAlign="center">
          <Icon
            as={FaExclamationCircle}
            boxSize={12}
            color="orange.500"
            mb={4}
          />
          <Heading size="lg" mb={4} color="heading">
            Organization Pending Approval
          </Heading>
          <Text color="text" fontSize="lg">
            Your organization is currently under review. You'll be notified once
            it's approved.
          </Text>
        </Box>
      </Container>
    </Box>
  );

  if (loading) {
    return (
      <>
        <Navbar />
        <Flex minH="100vh" justify="center" align="center">
          <Spinner size="xl" />
        </Flex>
      </>
    );
  }

  if (!organization?.approved) {
    return (
      <>
        <Navbar />
        <PendingApprovalMessage />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <Box
        minH="calc(100vh - 60px)"
        bg="primaryBackground"
        pt="60px"
        px={{ base: 4, md: 10 }}
      >
        <Container maxW="container.xl" py={8}>
          <VStack spacing={8} align="stretch">
            <Box textAlign={{ base: "center", md: "left" }}>
              <Heading as="h1" size="lg" color="heading">
                Organization Dashboard
              </Heading>
              <Text color="text" fontSize="xl">
                {organization?.name}
              </Text>
            </Box>

            {/* Stats Section */}
            {/* <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
              <Box
                bg={cardBg}
                p={6}
                borderRadius="lg"
                shadow="md"
                position="relative"
                overflow="hidden"
              >
                <Icon
                  as={FaDollarSign}
                  position="absolute"
                  right={4}
                  top={4}
                  boxSize={6}
                  color="green.500"
                  opacity={0.3}
                />
                <VStack align="start" spacing={1}>
                  <Text fontSize="sm" color="gray.500">
                    Total Raised
                  </Text>
                  <Heading size="lg" color="heading">
                    ${stats.totalRaised.toLocaleString()}
                  </Heading>
                </VStack>
              </Box>

              <Box
                bg={cardBg}
                p={6}
                borderRadius="lg"
                shadow="md"
                position="relative"
                overflow="hidden"
              >
                <Icon
                  as={FaUsers}
                  position="absolute"
                  right={4}
                  top={4}
                  boxSize={6}
                  color="blue.500"
                  opacity={0.3}
                />
                <VStack align="start" spacing={1}>
                  <Text fontSize="sm" color="gray.500">
                    Total Agents
                  </Text>
                  <Heading size="lg" color="heading">
                    {stats.totalAgents.toLocaleString()}
                  </Heading>
                </VStack>
              </Box>

              <Box
                bg={cardBg}
                p={6}
                borderRadius="lg"
                shadow="md"
                position="relative"
                overflow="hidden"
              >
                <Icon
                  as={FaHandshake}
                  position="absolute"
                  right={4}
                  top={4}
                  boxSize={6}
                  color="purple.500"
                  opacity={0.3}
                />
                <VStack align="start" spacing={1}>
                  <Text fontSize="sm" color="gray.500">
                    Total Supporters
                  </Text>
                  <Heading size="lg" color="heading">
                    {stats.totalSupporters.toLocaleString()}
                  </Heading>
                </VStack>
              </Box>
            </SimpleGrid> */}

            {/* Payouts Section */}
            <Box bg={cardBg} p={6} borderRadius="lg" shadow="md">
              <VStack align="stretch" spacing={4}>
                <Flex justify="space-between" align="center">
                  <HStack spacing={3}>
                    <Icon as={FaMoneyCheckAlt} boxSize={6} color="black" />
                    <Heading size="md" color="heading">
                      Payouts
                    </Heading>
                  </HStack>
                  {organization?.stripeAccountId &&
                    organization?.stripeConnected && (
                      <Button
                        rightIcon={<FaExternalLinkAlt />}
                        variant="outline"
                        colorScheme="blue"
                        size="sm"
                        onClick={handleDashboardClick}
                      >
                        Stripe Dashboard
                      </Button>
                    )}
                </Flex>

                {organization?.stripeAccountId &&
                organization?.stripeConnected ? (
                  <VStack align="start" spacing={2}>
                    <HStack>
                      <Icon as={FaCheckCircle} color="green.500" />
                      <Text color="text">Connected</Text>
                    </HStack>
                    <Text color="text">Your Stripe account is connected.</Text>
                  </VStack>
                ) : !organization?.stripeAccountId &&
                  !organization?.stripeConnected ? (
                  <VStack align="start" spacing={4}>
                    <HStack>
                      <Icon as={FaExclamationCircle} color="orange.500" />
                      <Text color="text">No payment account connected</Text>
                    </HStack>
                    <Text color="gray.500" fontSize="sm" align="left">
                      Setup your Stripe account to start securely receiving
                      payouts from your campaigns.
                    </Text>
                    <Button
                      leftIcon={<FaCog />}
                      variant="secondary"
                      size="sm"
                      onClick={handleInitializeStripe}
                      isLoading={loading}
                    >
                      Setup Stripe Account
                    </Button>
                  </VStack>
                ) : (
                  <VStack align="start" spacing={2}>
                    <HStack>
                      <Icon as={FaExclamationTriangle} color="red.500" />
                      <Text color="text">Connection Error</Text>
                    </HStack>
                    <Text color="gray.500" fontSize="sm">
                      There seems to be an issue with your Stripe connection.
                      Please contact support for assistance.
                    </Text>
                  </VStack>
                )}
              </VStack>
            </Box>

            {/* Users Section */}
            <Box bg={cardBg} p={6} borderRadius="lg" shadow="md">
              <VStack align="stretch" spacing={4}>
                <Flex justify="space-between" align="center">
                  <HStack spacing={3}>
                    <Icon as={FaUsers} boxSize={6} color="black" />
                    <Heading size="md" color="heading">
                      Administrators
                    </Heading>
                  </HStack>
                  <Button
                    leftIcon={<FaPlus />}
                    variant="secondary"
                    size="sm"
                    onClick={() => setIsAddUserModalOpen(true)}
                  >
                    Add User
                  </Button>
                </Flex>

                {loadingUsers ? (
                  <Spinner size="sm" />
                ) : users.length > 0 ? (
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Email</Th>

                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {users.map((user) => (
                        <Tr key={user.id}>
                          <Td>{user.name}</Td>
                          <Td>{user.email}</Td>
                          <Td isNumeric>
                            <Button
                              size="sm"
                              colorScheme="red"
                              variant="ghost"
                              onClick={() => initiateRemoveUser(user)}
                              isLoading={isRemovingUser}
                            >
                              Remove
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <Text color="gray.500">No users found</Text>
                )}
              </VStack>
            </Box>

            {/* Campaigns Section */}
            <Box
              width="100%"
              bg="white"
              p={{ base: 6, md: 8 }}
              borderRadius="xl"
              boxShadow="md"
            >
              <Flex align="center" justify="space-between" mb={6}>
                <HStack spacing={3}>
                  <Icon as={VscMegaphone} boxSize={6} color="black" />
                  <Heading as="h2" size="md" color="heading">
                    Campaigns
                  </Heading>
                </HStack>
                <Button
                  leftIcon={<FaPlus />}
                  variant="primary"
                  size="sm"
                  onClick={handleCreateCampaign}
                >
                  New Campaign
                </Button>
              </Flex>

              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                {campaigns.map((campaign) => (
                  <Box
                    key={campaign.id}
                    p={5}
                    bg="white"
                    borderRadius="lg"
                    border="1px"
                    borderColor="gray.200"
                    boxShadow="sm"
                    cursor="pointer"
                    onClick={() => handleCampaignClick(campaign.id)}
                    _hover={{
                      transform: "translateY(-2px)",
                      transition: "all 0.2s",
                      boxShadow: "md",
                    }}
                    position="relative"
                    role="group"
                  >
                    <VStack align="start" spacing={2} pr={6}>
                      <Heading size="sm" color="heading" fontWeight="medium">
                        {campaign.name?.en ||
                          campaign.name ||
                          "Unnamed Campaign"}
                      </Heading>
                    </VStack>
                    <Icon
                      as={FaChevronRight}
                      position="absolute"
                      top="50%"
                      right={4}
                      transform="translateY(-50%)"
                      color="gray.400"
                      _groupHover={{ color: "gray.600" }}
                    />
                  </Box>
                ))}
                {campaigns.length === 0 && (
                  <Box
                    p={5}
                    bg="white"
                    borderRadius="lg"
                    border="1px"
                    borderColor="gray.200"
                    boxShadow="sm"
                    textAlign="center"
                  >
                    <Text color="gray.500">No campaigns</Text>
                  </Box>
                )}
              </SimpleGrid>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Modal
        isOpen={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add User to Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={!!addUserError}>
              <FormLabel>User Email</FormLabel>
              <Input
                type="email"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                placeholder="Enter user's email"
              />
              <FormErrorMessage>{addUserError}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsAddUserModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleAddUser}
              isLoading={isAddingUser}
            >
              Add User
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isRemoveModalOpen}
        onClose={() => setIsRemoveModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Remove User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove {userToRemove?.name} from this
            organization?
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsRemoveModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={confirmRemoveUser}
              isLoading={isRemovingUser}
            >
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrganizationHome;
