import React from "react";
import { Box, Heading, Text, Container } from "@chakra-ui/react";
import Navbar from "../components/Navbar";

const PendingApproval = () => {
  return (
    <Box minH="100vh" bg="primaryBackground">
      <Navbar external={false} />
      <Container maxW="container.xl" py={10} px={20}>
        <Heading as="h1" size="xl" mb={4}>
          Account Pending Approval
        </Heading>
        <Text fontSize="lg">
          Thank you for creating an account. Your account is currently pending
          approval. You will be notified once your account has been approved and
          you can access the full features of the application.
        </Text>
      </Container>
    </Box>
  );
};

export default PendingApproval;
