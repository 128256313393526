import { useParams } from "react-router-dom";
import ResourceProtectedRoute from "./ResourceProtectedRoute";

const ProtectedOrganizationRoute = ({ children }) => {
  const { organizationId } = useParams();
  return (
    <ResourceProtectedRoute
      resourceType="organization"
      resourceId={organizationId}
      fallbackPath="/home"
    >
      {children}
    </ResourceProtectedRoute>
  );
};

export default ProtectedOrganizationRoute;
