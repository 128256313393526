import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Heading,
  useToast,
  SimpleGrid,
  useColorModeValue,
  Text,
  Flex,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import Navbar from "../components/Navbar";
import { UserAuth } from "../contexts/AuthContext";
import {
  FaBuilding,
  FaMegaphone,
  FaPlus,
  FaChevronRight,
  FaExclamationCircle,
} from "react-icons/fa";
import { VscMegaphone } from "react-icons/vsc";

const Home = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = UserAuth();
  const cardBg = useColorModeValue("white", "gray.700");

  useEffect(() => {
    const fetchUserData = async () => {
      if (user?.uid) {
        try {
          setLoading(true);
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);

          // Fetch organizations
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const orgsWithApprovalStatus = await Promise.all(
              (userData.organizations || []).map(async (org) => {
                const orgDoc = await getDoc(
                  doc(db, "organizations", org.organizationId)
                );
                const orgData = orgDoc.data();
                return {
                  ...org,
                  approved: orgData?.approved ?? false,
                  name: orgData?.name || "Unnamed Organization",
                };
              })
            );
            console.log("Organizations with status:", orgsWithApprovalStatus);
            setOrganizations(orgsWithApprovalStatus);
          }

          // Fetch campaigns
          if (userDoc.exists() && userDoc.data().campaigns) {
            const userCampaigns = userDoc.data().campaigns;
            const campaignPromises = userCampaigns.map((campaignId) =>
              getDoc(doc(db, "campaigns", campaignId))
            );
            const campaignDocs = await Promise.all(campaignPromises);
            const campaignsData = campaignDocs
              .filter((doc) => doc.exists())
              .map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
            setCampaigns(campaignsData);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          toast({
            title: "Error",
            description: "Failed to load user data",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, [user, toast]);

  const handleCreateOrganization = () => {
    navigate("/create-organization");
  };

  const handleOrganizationClick = (organizationId) => {
    navigate(`/organization/${organizationId}`);
  };

  const handleCampaignClick = (campaignId) => {
    navigate(`/campaign/${campaignId}`);
  };

  return (
    <>
      <Navbar />
      <Box
        minH="calc(100vh - 60px)"
        bg="primaryBackground"
        pt="60px"
        px={{ base: 4, md: 10 }}
      >
        <VStack spacing={8} maxWidth="1200px" margin="0 auto" py={8}>
          <Heading as="h1" size="lg" color="heading" alignSelf="flex-start">
            Welcome, {user?.firstName}!
          </Heading>

          {/* Organizations Section */}
          {organizations.length > 0 && (
            <Box
              width="100%"
              bg="white"
              p={{ base: 6, md: 8 }}
              borderRadius="xl"
              boxShadow="md"
            >
              <Flex justify="space-between" align="center" mb={6}>
                <HStack spacing={3}>
                  <Icon as={FaBuilding} boxSize={6} color="black" />
                  <Heading as="h2" size="md" color="heading">
                    Your Organizations
                  </Heading>
                </HStack>
                <Button
                  leftIcon={<FaPlus />}
                  variant="primary"
                  onClick={handleCreateOrganization}
                  size="sm"
                >
                  Create Organization
                </Button>
              </Flex>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                {organizations.map((org) => (
                  <Box
                    key={org.organizationId}
                    p={5}
                    bg="white"
                    borderRadius="lg"
                    border="1px"
                    borderColor="gray.200"
                    boxShadow="sm"
                    cursor="pointer"
                    onClick={() => handleOrganizationClick(org.organizationId)}
                    _hover={{
                      transform: "translateY(-2px)",
                      transition: "all 0.2s",
                      boxShadow: "md",
                    }}
                    position="relative"
                    role="group"
                  >
                    <Flex justify="space-between" align="center" pr={6}>
                      <Heading size="sm" color="heading" fontWeight="medium">
                        {org.name}
                      </Heading>
                      {!org.approved && (
                        <Icon
                          as={FaExclamationCircle}
                          color="orange.500"
                          boxSize={5}
                          title="Pending Approval"
                        />
                      )}
                    </Flex>
                    <Icon
                      as={FaChevronRight}
                      position="absolute"
                      top="50%"
                      right={4}
                      transform="translateY(-50%)"
                      color="gray.400"
                      _groupHover={{ color: "gray.600" }}
                    />
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          )}

          {/* Campaigns Section */}
          <Box
            width="100%"
            bg="white"
            p={{ base: 6, md: 8 }}
            borderRadius="xl"
            boxShadow="md"
          >
            <Flex align="center" mb={6}>
              <HStack spacing={3}>
                <Icon as={VscMegaphone} boxSize={6} color="black" />
                <Heading as="h2" size="md" color="heading">
                  Your Campaigns
                </Heading>
              </HStack>
            </Flex>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
              {campaigns.map((campaign) => (
                <Box
                  key={campaign.id}
                  p={5}
                  bg="white"
                  borderRadius="lg"
                  border="1px"
                  borderColor="gray.200"
                  boxShadow="sm"
                  cursor="pointer"
                  onClick={() => handleCampaignClick(campaign.id)}
                  _hover={{
                    transform: "translateY(-2px)",
                    transition: "all 0.2s",
                    boxShadow: "md",
                  }}
                  position="relative"
                  role="group"
                >
                  <VStack align="start" spacing={2} pr={6}>
                    <Heading size="sm" color="heading" fontWeight="medium">
                      {campaign.name?.en || campaign.name || "Unnamed Campaign"}
                    </Heading>
                    {/* <Text
                      color={campaign.isAdmin ? "green.500" : "gray.900"}
                      fontSize="sm"
                      fontWeight="medium"
                    >
                      {campaign.isAdmin ? "Admin" : "Member"}
                    </Text> */}
                  </VStack>
                  <Icon
                    as={FaChevronRight}
                    position="absolute"
                    top="50%"
                    right={4}
                    transform="translateY(-50%)"
                    color="gray.400"
                    _groupHover={{ color: "gray.600" }}
                  />
                </Box>
              ))}
              {campaigns.length === 0 && (
                <Box
                  p={5}
                  bg="white"
                  borderRadius="lg"
                  border="1px"
                  borderColor="gray.200"
                  boxShadow="sm"
                  textAlign="center"
                >
                  <Text color="gray.500">No active campaigns</Text>
                </Box>
              )}
            </SimpleGrid>
          </Box>

          {/* Create Organization Section - Only show if user has no organizations */}
          {organizations.length === 0 && (
            <Box
              width="100%"
              bg="white"
              p={{ base: 6, md: 8 }}
              borderRadius="xl"
              boxShadow="md"
            >
              <VStack spacing={4} align="start">
                <Heading size="md" color="heading">
                  Want to start a campaign?
                </Heading>
                <Text color="text" align="left">
                  Create an organization to start raising money with your own
                  campaigns.
                </Text>
                <Button
                  leftIcon={<FaPlus />}
                  variant="primary"
                  size="md"
                  onClick={handleCreateOrganization}
                  mt={4}
                >
                  Create an Organization
                </Button>
              </VStack>
            </Box>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default Home;
