import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  FormErrorMessage,
  Heading,
  Text,
  Image,
  Flex,
  Link,
  Container,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { db } from "../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import logo from "../assets/logos/logo.png";
import { apiUrl } from "../config";
import { useLanguage } from "../contexts/LanguageContext";
import Navbar from "../components/Navbar"; // Import the Navbar component

const AddCustomer = () => {
  const navigate = useNavigate();
  const { campaignId, agentId, lang } = useParams();
  const [campaigns, setCampaigns] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isCampaignActive, setIsCampaignActive] = useState(true);
  const { language, setLanguage, translate } = useLanguage();

  const getLocalizedValue = (value) => {
    if (typeof value === "object" && value !== null) {
      return language === "fr" && value.fr ? value.fr : value.en || "";
    }
    return value || "";
  };

  useEffect(() => {
    const fetchCampaigns = async () => {
      const campaignsRef = collection(db, "campaigns");
      const querySnapshot = await getDocs(campaignsRef);
      const campaignsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name, // Store the whole name object
      }));
      setCampaigns(campaignsData);
    };

    fetchCampaigns();

    if (campaignId) {
      fetchCampaignDetails(campaignId);
      fetchAgents(campaignId);
    }

    // Set language based on the 'lang' parameter
    if (lang === "fr") {
      setLanguage("fr");
    } else {
      setLanguage("en");
    }
  }, [campaignId, lang, setLanguage]);

  const initialValues = {
    campaignId: campaignId || "",
    agentId: agentId || "",
    name: "",
    email: "",
    confirmEmail: "",
    phoneNumber: "",
    language: language || "en",
    agreeToTerms: false,
  };

  const handleSubmit = async (values, actions) => {
    console.log("Values:", values);
    try {
      await axios
        .post(`${apiUrl}/create-checkout-session`, values)
        .then((response) => {
          if (response.status === 200) {
            window.location = response.data.url;
          } else {
            return Promise.reject(response.data);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
      console.log("Customer added successfully");
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      console.error("Error adding customer:", error);
      actions.setSubmitting(false);
    }
  };

  const fetchCampaignDetails = async (campaignId) => {
    const campaignRef = doc(db, "campaigns", campaignId);
    const campaignSnap = await getDoc(campaignRef);
    if (campaignSnap.exists()) {
      const data = campaignSnap.data();
      setSelectedCampaign({
        id: campaignSnap.id,
        ...data,
      });
      setIsCampaignActive(data.active || false);
    } else {
      setSelectedCampaign(null);
      setIsCampaignActive(false);
    }
  };

  const fetchAgents = async (campaignId) => {
    const agentsRef = collection(db, "campaigns", campaignId, "agents");
    const agentsSnapshot = await getDocs(agentsRef);
    const agentsData = agentsSnapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
    }));
    setAgents(agentsData);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      translate("addCustomer.validationErrors.nameRequired")
    ),
    email: Yup.string()
      .email(translate("addCustomer.validationErrors.invalidEmail"))
      .required(translate("addCustomer.validationErrors.emailRequired")),
    confirmEmail: Yup.string()
      .oneOf(
        [Yup.ref("email"), null],
        translate("addCustomer.validationErrors.emailsMustMatch")
      )
      .required(translate("addCustomer.validationErrors.confirmEmailRequired")),
    campaignId: Yup.string().required(
      translate("addCustomer.validationErrors.campaignRequired")
    ),
    phoneNumber: Yup.string().test(
      "valid-phone",
      translate("addCustomer.validationErrors.phoneNumberInvalid"),
      (value) => {
        if (!value) return true; // Optional field
        // Add your phone number validation logic here
        return /^[0-9+\-\s()]*$/.test(value);
      }
    ),
    agreeToTerms: Yup.boolean()
      .oneOf(
        [true],
        translate("addCustomer.validationErrors.agreeToTermsRequired")
      )
      .required(translate("addCustomer.validationErrors.agreeToTermsRequired")),
  });

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      bg="primaryBackground"
    >
      <Navbar external={true} />
      <Container maxW="container.xl" centerContent>
        <Heading
          as="h1"
          size="xl"
          mb={10}
          mt={20}
          textAlign="center"
          color="heading"
        >
          {selectedCampaign
            ? language === "fr" && selectedCampaign.name.fr
              ? selectedCampaign.name.fr
              : selectedCampaign.name.en
            : translate("addCustomer.heading")}
        </Heading>
        <Box
          maxWidth="500px"
          width="100%"
          bg="secondaryBackground"
          p={10}
          borderRadius="xl"
          boxShadow="md"
        >
          {selectedCampaign && !isCampaignActive ? (
            <VStack spacing={4} align="center">
              <Text color="red.500" fontWeight="bold">
                {translate("addCustomer.campaignInactive")}
              </Text>
              <Button
                mt={4}
                variant="secondary"
                onClick={() => {
                  setSelectedCampaign(null);
                  // Reset the form
                  window.location.reload();
                }}
              >
                {translate("addCustomer.backButton")}
              </Button>
            </VStack>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <Form>
                  <VStack spacing={4}>
                    {selectedCampaign && (
                      <Box width="100%" p={4} bg="gray.100" borderRadius="md">
                        <Heading size="md" mb={2}>
                          {language === "fr" && selectedCampaign.name.fr
                            ? selectedCampaign.name.fr
                            : selectedCampaign.name.en}
                        </Heading>
                        <Text>
                          {language === "fr" && selectedCampaign.description.fr
                            ? selectedCampaign.description.fr
                            : selectedCampaign.description.en}
                        </Text>
                        <Text mt={2} fontWeight="bold">
                          {translate("addCustomer.couponBookPrice")}: $
                          {selectedCampaign.couponBookPrice}
                        </Text>
                        <Button
                          onClick={() => {
                            if (selectedCampaign) {
                              if (selectedCampaign.offeringURL) {
                                window.open(
                                  selectedCampaign.offeringURL,
                                  "_blank"
                                );
                              } else {
                                navigate(
                                  `/business-list/${selectedCampaign.id}`
                                );
                              }
                            }
                          }}
                          variant={"secondary"}
                          mt={2}
                          size={"sm"}
                        >
                          {translate("addCustomer.seeOfferingsButton")}
                        </Button>
                      </Box>
                    )}

                    <Field name="campaignId">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.campaignId && form.touched.campaignId
                          }
                        >
                          <FormLabel htmlFor="campaignId">
                            {translate("addCustomer.campaign")}
                          </FormLabel>
                          <Select
                            {...field}
                            id="campaignId"
                            placeholder={translate(
                              "addCustomer.selectCampaign"
                            )}
                            isDisabled={!!campaignId}
                            onChange={(e) => {
                              field.onChange(e);
                              fetchCampaignDetails(e.target.value);
                              fetchAgents(e.target.value);
                            }}
                          >
                            {campaigns.map((campaign) => (
                              <option key={campaign.id} value={campaign.id}>
                                {getLocalizedValue(campaign.name)}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.campaignId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="agentId">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.agentId && form.touched.agentId
                          }
                        >
                          <FormLabel htmlFor="agentId">
                            {translate("addCustomer.fundraiser")}
                          </FormLabel>
                          <Select
                            {...field}
                            id="agentId"
                            placeholder={translate(
                              "addCustomer.selectFundraiser"
                            )}
                            isDisabled={!form.values.campaignId || !!agentId}
                          >
                            {agents.map((agent) => (
                              <option key={agent.id} value={agent.id}>
                                {agent.name}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.agentId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <FormLabel htmlFor="name">
                            {translate("addCustomer.name")}
                          </FormLabel>
                          <Input
                            {...field}
                            id="name"
                            placeholder={translate(
                              "addCustomer.namePlaceholder"
                            )}
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel htmlFor="email">
                            {translate("addCustomer.email")}
                          </FormLabel>
                          <Input
                            {...field}
                            id="email"
                            placeholder={translate(
                              "addCustomer.emailPlaceholder"
                            )}
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="confirmEmail">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.confirmEmail &&
                            form.touched.confirmEmail
                          }
                        >
                          <FormLabel htmlFor="confirmEmail">
                            {translate("addCustomer.confirmEmail")}
                          </FormLabel>
                          <Input
                            {...field}
                            id="confirmEmail"
                            placeholder={translate(
                              "addCustomer.confirmEmailPlaceholder"
                            )}
                          />
                          <FormErrorMessage>
                            {form.errors.confirmEmail}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="phoneNumber">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.phoneNumber && form.touched.phoneNumber
                          }
                        >
                          <FormLabel htmlFor="phoneNumber">
                            {translate("addCustomer.phoneNumber")}
                          </FormLabel>
                          <Input
                            {...field}
                            id="phoneNumber"
                            placeholder={translate(
                              "addCustomer.phoneNumberPlaceholder"
                            )}
                          />
                          <FormErrorMessage>
                            {form.errors.phoneNumber}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="agreeToTerms">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.agreeToTerms &&
                            form.touched.agreeToTerms
                          }
                        >
                          <Flex alignItems="flex-start">
                            <input
                              type="checkbox"
                              {...field}
                              id="agreeToTerms"
                              style={{ marginRight: "8px", marginTop: "4px" }}
                            />
                            <FormLabel htmlFor="agreeToTerms" mb={0}>
                              {translate("addCustomer.agreeToTerms")}{" "}
                              <Link
                                as={RouterLink}
                                to="/terms"
                                color="blue.500"
                                isExternal
                              >
                                {translate("addCustomer.termsAndConditions")}
                              </Link>{" "}
                              {translate("addCustomer.and")}{" "}
                              <Link
                                as={RouterLink}
                                to="/privacy"
                                color="blue.500"
                                isExternal
                              >
                                {translate("addCustomer.privacyPolicy")}
                              </Link>
                            </FormLabel>
                          </Flex>
                          <FormErrorMessage>
                            {form.errors.agreeToTerms}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Button
                      mt={4}
                      variant={"primary"}
                      isLoading={props.isSubmitting}
                      type="submit"
                      width="100%"
                    >
                      {translate("addCustomer.proceedButton")}
                    </Button>

                    <Flex alignItems="center" justifyContent="center" mt={2}>
                      <Text fontSize="sm" color="gray.500" mr={2}>
                        {translate("addCustomer.securelyPoweredBy")}
                      </Text>
                      <Image
                        src="https://stripe.com/img/v3/home/twitter.png"
                        alt="Stripe logo"
                        height="20px"
                      />
                    </Flex>
                  </VStack>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default AddCustomer;
