import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Heading,
  VStack,
  Button,
  useBreakpointValue,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  Icon,
  IconButton,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  FormErrorMessage,
  Switch,
} from "@chakra-ui/react";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import DataTable from "react-data-table-component";
import Navbar from "../components/Navbar";
import {
  FaUsers,
  FaStore,
  FaHandshake,
  FaDollarSign,
  FaPlus,
  FaTrash,
  FaEdit,
  FaLink,
} from "react-icons/fa";
import { UserAuth } from "../contexts/AuthContext";
import axios from "axios";
import { apiUrl } from "../config";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  isAdmin: Yup.boolean(),
});

const CampaignHome = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [campaignStats, setCampaignStats] = useState({
    agentCount: 0,
    businessCount: 0,
    totalAmountSold: 0,
    totalSalesCount: 0,
  });
  const toast = useToast();

  const buttonSize = useBreakpointValue({ base: "sm", md: "sm" });
  const idToken = sessionStorage.getItem("idToken");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [fundraiserToRemove, setFundraiserToRemove] = useState(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      // Fetch campaign name and active status
      const campaignDoc = await getDoc(doc(db, "campaigns", campaignId));
      if (campaignDoc.exists()) {
        const campaignData = campaignDoc.data();
        setCampaignName(campaignData.name.en);
        setIsActive(campaignData.active || false);
      }

      // Fetch businesses
      const businessesQuery = query(
        collection(db, "businesses"),
        where("campaignId", "==", campaignId)
      );
      const businessesSnapshot = await getDocs(businessesQuery);
      const businessesData = businessesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBusinesses(businessesData);

      // Fetch customers
      const customersQuery = query(
        collection(db, "customers"),
        where("campaignId", "==", campaignId)
      );
      const customersSnapshot = await getDocs(customersQuery);
      setCustomers(
        customersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );

      // Fetch agents
      const agentsQuery = query(
        collection(db, `campaigns/${campaignId}/agents`)
      );
      const agentsSnapshot = await getDocs(agentsQuery);
      const agentsData = agentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAgents(agentsData);

      // Calculate campaign stats
      const totalAmountSold = agentsData.reduce(
        (sum, agent) => sum + (agent.saleAmount || 0),
        0
      );
      const totalSalesCount = agentsData.reduce(
        (sum, agent) => sum + (agent.saleCount || 0),
        0
      );

      setCampaignStats({
        agentCount: agentsData.length,
        businessCount: businessesData.length,
        totalAmountSold,
        totalSalesCount,
      });

      // Check if the current user is an admin
      const agentRef = doc(db, `campaigns/${campaignId}/agents`, user.uid);
      const agentDoc = await getDoc(agentRef);
      if (agentDoc.exists() && agentDoc.data().isAdmin) {
        setIsAdmin(true);
      }
    };

    fetchData();
  }, [campaignId, user]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name?.en || "",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Website URL",
      selector: (row) => row.websiteUrl,
      sortable: true,
    },
    {
      name: "Coupon Details",
      selector: (row) => row.couponDetails?.en || "",
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.createdAt?.toDate().toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button
          onClick={() => navigate(`/edit-business/${row.id}`)}
          size="sm"
          variant="outline"
        >
          Edit
        </Button>
      ),
    },
  ];

  const customerColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Purchase Date",
      selector: (row) => row.createdAt?.toDate().toLocaleDateString(),
      sortable: true,
    },
  ];

  const copyToClipboard = (agentId) => {
    const url = `https://app.coupal.io/new-customer/${campaignId}/${agentId}`;
    navigator.clipboard.writeText(url).then(() => {
      toast({
        title: "URL Copied",
        description: "The agent's URL has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const copyNewCustomerUrl = () => {
    const url = `https://app.coupal.io/new-customer/${campaignId}`;
    navigator.clipboard.writeText(url).then(() => {
      toast({
        title: "URL Copied",
        description: "The new customer URL has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const agentColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Sales Count",
      selector: (row) => row.saleCount || 0,
      sortable: true,
    },
    {
      name: "Sales Amount",
      selector: (row) => `$${(row.saleAmount || 0).toFixed(2)}`,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <HStack spacing={2}>
          <IconButton
            icon={<FaLink />}
            aria-label="Copy URL"
            size="sm"
            variant="ghost"
            colorScheme="blue"
            onClick={() => copyToClipboard(row.id)}
          />
          {isAdmin && (
            <>
              <IconButton
                icon={<FaEdit />}
                aria-label="Edit Fundraiser"
                size="sm"
                variant="ghost"
                colorScheme="green"
                onClick={() => navigate(`/edit-agent/${campaignId}/${row.id}`)}
              />
              <IconButton
                icon={<FaTrash />}
                aria-label="Remove Fundraiser"
                size="sm"
                variant="ghost"
                colorScheme="red"
                onClick={() => initiateRemoveAgent(row)}
              />
            </>
          )}
        </HStack>
      ),
      width: "200px",
    },
  ];

  const initiateRemoveAgent = (agent) => {
    setFundraiserToRemove(agent);
    setIsRemoveModalOpen(true);
  };

  const confirmRemoveAgent = async () => {
    if (!fundraiserToRemove) return;

    try {
      await axios.delete(
        `${apiUrl}/campaigns/${campaignId}/agents/${fundraiserToRemove.id}`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      // Update local state
      setAgents(agents.filter((agent) => agent.id !== fundraiserToRemove.id));
      setCampaignStats((prev) => ({
        ...prev,
        agentCount: prev.agentCount - 1,
      }));

      toast({
        title: "Success",
        description: "Fundraiser removed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setIsRemoveModalOpen(false);
      setFundraiserToRemove(null);
    } catch (error) {
      console.error("Error removing fundraiser:", error);
      toast({
        title: "Error",
        description: "Failed to remove fundraiser. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddAgent = async (values, actions) => {
    try {
      const response = await axios.post(
        `${apiUrl}/campaigns/${campaignId}/agents`,
        values,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      // Update local state with new agent
      setAgents([...agents, response.data.agent]);
      setCampaignStats((prev) => ({
        ...prev,
        agentCount: prev.agentCount + 1,
      }));

      toast({
        title: "Success",
        description: "Fundraiser added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      actions.setSubmitting(false);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding fundraiser:", error);
      toast({
        title: "Error",
        description: error.response?.data?.error || "Failed to add fundraiser",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      actions.setSubmitting(false);
    }
  };

  const fundraisersStat = (
    <Stat bg="white" p={4} borderRadius="lg" boxShadow="md" position="relative">
      <Flex justifyContent="space-between" alignItems="flex-start">
        <StatLabel fontSize="lg">Fundraisers</StatLabel>
        <Icon as={FaUsers} boxSize={7} color="black" />
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-end" mt={4}>
        <StatNumber fontSize="3xl">{campaignStats.agentCount}</StatNumber>
        {isAdmin && (
          <IconButton
            size="sm"
            onClick={() => setIsModalOpen(true)}
            icon={<Icon as={FaPlus} />}
            aria-label="Add Fundraiser"
          />
        )}
      </Flex>
    </Stat>
  );

  const addAgentModal = (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Fundraiser</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ email: "", isAdmin: false }}
          validationSchema={validationSchema}
          onSubmit={handleAddAgent}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <VStack spacing={4}>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                        isRequired
                      >
                        <FormLabel>Email</FormLabel>
                        <Input
                          {...field}
                          placeholder="Enter fundraiser's email"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="isAdmin">
                    {({ field }) => (
                      <FormControl>
                        <Checkbox {...field}>Make Admin</Checkbox>
                      </FormControl>
                    )}
                  </Field>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  mr={3}
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={props.isSubmitting}
                >
                  Add Fundraiser
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );

  const handleActiveToggle = async () => {
    try {
      await axios.put(
        `${apiUrl}/campaigns/${campaignId}/toggleActive`,
        {
          active: !isActive,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      setIsActive(!isActive);
      toast({
        title: "Success",
        description: `Campaign ${
          !isActive ? "activated" : "deactivated"
        } successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error toggling campaign status:", error);
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "Failed to update campaign status",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack
          align="stretch"
          maxWidth="1200px"
          margin="0 auto"
          pt={8}
          spacing={8}
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "space-between" }}
            align={{ base: "center", md: "flex-start" }}
            gap={4}
          >
            <Box textAlign={{ base: "center", md: "left" }}>
              <Heading as="h1" size="lg" color="heading">
                Campaign Dashboard
              </Heading>
              <Text color="text" fontSize="xl">
                {campaignName}
              </Text>
            </Box>
            <VStack
              spacing={4}
              align={{ base: "center", sm: "flex-end" }}
              width={{ base: "100%", sm: "auto" }}
            >
              <Flex
                direction={{ base: "column", sm: "row" }}
                gap={2}
                width="100%"
              >
                <Button
                  onClick={copyNewCustomerUrl}
                  size={buttonSize}
                  variant="primary"
                  width={{ base: "full", sm: "auto" }}
                >
                  Copy Purchase URL
                </Button>
                {isAdmin && (
                  <Button
                    onClick={() => navigate(`/edit-campaign/${campaignId}`)}
                    size={buttonSize}
                    variant="secondary"
                    width={{ base: "full", sm: "auto" }}
                  >
                    Edit Campaign
                  </Button>
                )}
              </Flex>
              {isAdmin && (
                <Flex
                  align="center"
                  gap={2}
                  width={{ base: "100%", sm: "auto" }}
                  justify={{ base: "center", sm: "flex-end" }}
                >
                  <Switch
                    isChecked={isActive}
                    onChange={handleActiveToggle}
                    isDisabled={!isAdmin}
                  />
                  <Text color={isActive ? "green.500" : "gray.500"}>
                    {isActive ? "Active" : "Inactive"}
                  </Text>
                </Flex>
              )}
            </VStack>
          </Flex>

          <SimpleGrid
            columns={{ base: 1, sm: 2, lg: 4 }}
            spacing={6}
            width="100%"
          >
            <Stat
              bg="white"
              p={4}
              borderRadius="lg"
              boxShadow="md"
              position="relative"
            >
              <Flex justifyContent="space-between" alignItems="flex-start">
                <StatLabel fontSize="lg">Fundraisers</StatLabel>
                <Icon as={FaUsers} boxSize={7} color="black" />
              </Flex>
              <Flex justifyContent="space-between" alignItems="flex-end" mt={4}>
                <StatNumber fontSize="3xl">
                  {campaignStats.agentCount}
                </StatNumber>
                {isAdmin && (
                  <IconButton
                    size="sm"
                    onClick={() => setIsModalOpen(true)}
                    icon={<Icon as={FaPlus} />}
                    aria-label="Add Fundraiser"
                  />
                )}
              </Flex>
            </Stat>
            <Stat
              bg="white"
              p={4}
              borderRadius="lg"
              boxShadow="md"
              position="relative"
            >
              <Flex justifyContent="space-between" alignItems="flex-start">
                <StatLabel fontSize="lg">Advertisers</StatLabel>
                <Icon as={FaStore} boxSize={7} color="black" />
              </Flex>
              <Flex justifyContent="space-between" alignItems="flex-end" mt={4}>
                <StatNumber fontSize="3xl">
                  {campaignStats.businessCount}
                </StatNumber>
                <IconButton
                  size="sm"
                  onClick={() => navigate(`/add-business`)}
                  icon={<Icon as={FaPlus} />}
                  aria-label="Add Advertiser"
                />
              </Flex>
            </Stat>
            <Stat
              bg="white"
              p={4}
              borderRadius="lg"
              boxShadow="md"
              position="relative"
            >
              <Flex justifyContent="space-between" alignItems="flex-start">
                <StatLabel fontSize="lg">Supporters</StatLabel>
                <Icon as={FaHandshake} boxSize={7} color="black" />
              </Flex>
              <Flex justifyContent="space-between" alignItems="flex-end" mt={4}>
                <StatNumber fontSize="3xl">
                  {campaignStats.totalSalesCount}
                </StatNumber>
              </Flex>
            </Stat>
            <Stat
              bg="white"
              p={4}
              borderRadius="lg"
              boxShadow="md"
              position="relative"
            >
              <Flex justifyContent="space-between" alignItems="flex-start">
                <StatLabel fontSize="lg">Total Amount Sold</StatLabel>
                <Icon as={FaDollarSign} boxSize={7} color="black" />
              </Flex>
              <Flex justifyContent="space-between" alignItems="flex-end" mt={4}>
                <StatNumber fontSize="3xl">
                  ${campaignStats.totalAmountSold.toFixed(2)}
                </StatNumber>
              </Flex>
            </Stat>
          </SimpleGrid>

          <Box
            width="100%"
            bg="secondaryBackground"
            p={{ base: 4, md: 10 }}
            borderRadius="xl"
            boxShadow="md"
          >
            <VStack spacing={6} align="stretch">
              <Tabs variant="enclosed">
                <TabList overflowX="auto" whiteSpace="nowrap">
                  <Tab>Advertisers</Tab>
                  <Tab>Fundraisers</Tab>
                  <Tab>Supporters</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel px={0}>
                    <Box overflowX="auto">
                      <DataTable
                        columns={columns}
                        data={businesses}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        highlightOnHover
                        striped
                        responsive
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box overflowX="auto">
                      <DataTable
                        columns={agentColumns}
                        data={agents}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        highlightOnHover
                        striped
                        responsive
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box overflowX="auto">
                      <DataTable
                        columns={customerColumns}
                        data={customers}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        highlightOnHover
                        striped
                        responsive
                      />
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </Box>
        </VStack>
      </Box>
      {addAgentModal}

      <Modal
        isOpen={isRemoveModalOpen}
        onClose={() => setIsRemoveModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Fundraiser</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove {fundraiserToRemove?.name} from this
            campaign? This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsRemoveModalOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmRemoveAgent}>
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CampaignHome;
