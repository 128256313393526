import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  FormErrorMessage,
  Checkbox,
} from "@chakra-ui/react";
import { Field } from "formik";

const AgentInput = ({ index, remove, isLast }) => {
  return (
    <Box width="100%">
      <VStack spacing={4} align="stretch" width="100%">
        <Field name={`agents.${index}.email`}>
          {({ field, form }) => (
            <FormControl
              isInvalid={
                form.errors.agents?.[index]?.email &&
                form.touched.agents?.[index]?.email
              }
              width="100%"
            >
              <FormLabel htmlFor={`agents.${index}.email`}>Email</FormLabel>
              <Input
                {...field}
                id={`agents.${index}.email`}
                placeholder="Enter Fundraiser email"
                width="100%"
              />
              <FormErrorMessage>
                {form.errors.agents?.[index]?.email}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name={`agents.${index}.isAdmin`}>
          {({ field }) => (
            <Checkbox {...field} id={`agents.${index}.isAdmin`}>
              Set as Admin
            </Checkbox>
          )}
        </Field>
        {!isLast && (
          <Button onClick={() => remove(index)} colorScheme="red" width="100%">
            Remove
          </Button>
        )}
      </VStack>
    </Box>
  );
};

export default AgentInput;
